<template>
  <div class="margin-config fill-height text-center" v-if="!isLoading">
    <v-row justify="center" align="center">
      <v-col cols="12">
        <span class="my-folders-text">Configuraciones / </span>
        <span>Notificaciones</span>
      </v-col>
    </v-row>
    <v-row no-gutters class="mb-4" justify="center">
      <v-col cols="12">
        <v-text-field
          v-model="notiListSearch"
          label="Buscar..."
        >
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <span class="my-folders-text">
          <v-icon color="secondary">
            mdi-exclamation-thick
          </v-icon>
          Cada notificación contiene palabras claves que se reemplazarán cuando llegue la notificación,
          cada palabra clave debe iniciar con el símbolo "*".
          Para consultar las palabras claves se debe poner el mouse sobre el ícono "?" de notificación.
        </span>
      </v-col>
    </v-row>
    <v-data-iterator
      :items="notiList"
      :search="notiListSearch"
      loading-text="Cargando"
      :loading="notiListLoader"
      no-data-text="No hay notificaciones creadas"
      :items-per-page="4"
      :footer-props="{itemsPerPageOptions:[4, 8, -1]}"
    >
      <template v-slot:default="props">
        <v-row style="text-align: left;" no-gutters>
          <v-col cols="12" sm="6" md="6" v-for="(notiItem, index) in props.items" :key="index">
            <v-row justify="space-between">
              <v-col style="align-self: center;" class="mx-3 py-0" cols="auto">
                <h5>
                  Nombre: {{ notiItem.title }}
                </h5>
              </v-col>
              <v-col class="mx-3 py-0" cols="auto">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                      >
                        <v-icon
                          class="mx-1"
                          color="primary"
                        >
                          mdi-help
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ notiItem.helpText }}</span>
                  </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-on="on"
                      v-bind="attrs"
                      @click="saveInfo(notiItem)"
                    >
                      <v-icon
                        class="mx-1"
                        color="primary"
                      >
                        mdi-content-save-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Guardar</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-col class="my-0 py-0" cols="12">
              <v-textarea
                class="form-field mt-2"
                name="Title"
                outlined
                single-line
                background-color="white"
                height="100px"
                v-model="vmodelArray[notiItem.id]"
                hide-details
              />
            </v-col>
            <v-row class="mx-1">
              <v-col cols="auto">
                <span class="my-folders-text"> Modificado por {{ notiItem.usermod }} </span>
                <span class="my-folders-text"> el {{ notiItem.updated }} </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <div v-if="createOrEditBtnLoader" class="loading-container">
      <Loader />
    </div>
  </div>
  <div v-else class="loading-container">
    <Loader />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { colors } from '@/utils/colors'

/* Components */
import Loader from '@/components/Base/Loader'

export default {
  name: 'Notificaciones',
  components: {
    Loader
  },
  mixins: [],

  data () {
    return {
      isLoading: false,
      notiList: [],
      notiListSearch: '',
      notiListLoader: false,
      createAreaDialog: false,
      formNotification: {
        content: '',
        id: '',
        isValid: ''
      },
      textFieldRules: [
        v => !!v || 'Este campo es requerido'
      ],
      isCreateOrUpdate: false, // false for edit and true for create,
      createOrEditBtnLoader: false,
      vmodelArray: [],
      isEditSelected: false
    }
  },

  created () {
    this.getNotifications()
  },

  computed: {
  },

  methods: {
    ...mapActions('administration', ['getAdminNotifications', 'updateAdminNotification']),
    ...mapMutations({ setSnackbar: 'SET_SNACKBAR' }),

    getNotifications () {
      this.notiListLoader = true
      this.vmodelArray = []
      this.getAdminNotifications()
        .then(response => {
          this.notiListLoader = false
          this.notiList = response.data
          response.data.forEach(element => {
            this.vmodelArray[element.id] = element.content
          })
          this.closeDialog()
        })
        .catch(() => {
          this.notiListLoader = false
        })
    },

    saveInfo (item) {
      this.formNotification.id = item.id
      this.formNotification.content = this.vmodelArray[item.id]
      this.updateNotification(item)
    },

    updateNotification () {
      this.createOrEditBtnLoader = true
      const request = {
        id: this.formNotification.id,
        content: this.formNotification.content
      }
      this.updateAdminNotification(request)
        .then(() => {
          this.createOrEditBtnLoader = false
          this.getNotifications()
          this.setSnackbar({
            text: 'Notificación actualizada correctamente',
            timeout: 5000,
            showing: true,
            color: colors.success
          })
        })
    },

    getCreateOrEdit () {
      return this.isCreateOrUpdate ? 'Crear' : 'Editar'
    },

    closeDialog () {
      this.createAreaDialog = false
      this.$refs.formNotification.resetValidation()
      this.formNotification = {
        content: '',
        isValid: false
      }
    }
  }
}
</script>

<style lang="scss">
.margin-config{
  padding-left: 10%;
  padding-right: 5%;
}

.my-folders-text{
  color: gray;
}

.center-dialog{
  text-align: center;
  justify-content: center;
}

</style>
