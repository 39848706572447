import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('div',{staticClass:"margin-config fill-height text-center"},[_c(VRow,{attrs:{"justify":"center","align":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c('span',{staticClass:"my-folders-text"},[_vm._v("Configuraciones / ")]),_c('span',[_vm._v("Notificaciones")])])],1),_c(VRow,{staticClass:"mb-4",attrs:{"no-gutters":"","justify":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Buscar..."},model:{value:(_vm.notiListSearch),callback:function ($$v) {_vm.notiListSearch=$$v},expression:"notiListSearch"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('span',{staticClass:"my-folders-text"},[_c(VIcon,{attrs:{"color":"secondary"}},[_vm._v(" mdi-exclamation-thick ")]),_vm._v(" Cada notificación contiene palabras claves que se reemplazarán cuando llegue la notificación, cada palabra clave debe iniciar con el símbolo \"*\". Para consultar las palabras claves se debe poner el mouse sobre el ícono \"?\" de notificación. ")],1)])],1),_c(VDataIterator,{attrs:{"items":_vm.notiList,"search":_vm.notiListSearch,"loading-text":"Cargando","loading":_vm.notiListLoader,"no-data-text":"No hay notificaciones creadas","items-per-page":4,"footer-props":{itemsPerPageOptions:[4, 8, -1]}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c(VRow,{staticStyle:{"text-align":"left"},attrs:{"no-gutters":""}},_vm._l((props.items),function(notiItem,index){return _c(VCol,{key:index,attrs:{"cols":"12","sm":"6","md":"6"}},[_c(VRow,{attrs:{"justify":"space-between"}},[_c(VCol,{staticClass:"mx-3 py-0",staticStyle:{"align-self":"center"},attrs:{"cols":"auto"}},[_c('h5',[_vm._v(" Nombre: "+_vm._s(notiItem.title)+" ")])]),_c(VCol,{staticClass:"mx-3 py-0",attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"mx-1",attrs:{"color":"primary"}},[_vm._v(" mdi-help ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(notiItem.helpText))])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.saveInfo(notiItem)}}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"mx-1",attrs:{"color":"primary"}},[_vm._v(" mdi-content-save-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Guardar")])])],1)],1),_c(VCol,{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c(VTextarea,{staticClass:"form-field mt-2",attrs:{"name":"Title","outlined":"","single-line":"","background-color":"white","height":"100px","hide-details":""},model:{value:(_vm.vmodelArray[notiItem.id]),callback:function ($$v) {_vm.$set(_vm.vmodelArray, notiItem.id, $$v)},expression:"vmodelArray[notiItem.id]"}})],1),_c(VRow,{staticClass:"mx-1"},[_c(VCol,{attrs:{"cols":"auto"}},[_c('span',{staticClass:"my-folders-text"},[_vm._v(" Modificado por "+_vm._s(notiItem.usermod)+" ")]),_c('span',{staticClass:"my-folders-text"},[_vm._v(" el "+_vm._s(notiItem.updated)+" ")])])],1)],1)}),1)]}}],null,false,3660240522)}),(_vm.createOrEditBtnLoader)?_c('div',{staticClass:"loading-container"},[_c('Loader')],1):_vm._e()],1):_c('div',{staticClass:"loading-container"},[_c('Loader')],1)}
var staticRenderFns = []

export { render, staticRenderFns }